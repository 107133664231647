<template>
    <div class="info-wrap">
        <div class="head-box">
            <div class="head-left">
                <div class="head-wrap">
                    <div class="report-title">个人信息</div>
                    <div class="left-main">
                        <img :src="userInfo.avatar ? userInfo.avatar:require('../assets/images/teacher/default-header.png')"
                             alt="">
                        <div class="left-main-content">
                            <span class="stu-name">{{userInfo.username}}</span>
                            <div class="left-item">
                                <span class="item-name">身份证号：</span>
                                <span class="item-content">{{userInfo.id_number}}</span>
                            </div>
                            <div class="left-item">
                                        <span class="item-name gender">
                                            <span>性&nbsp&nbsp&nbsp</span>
                                            <span>别</span>
                                        </span>
                                <span class="item-content">
                                            <span>：</span>
                                            <span class="gender-box female" v-if="userInfo.gender == '女'">
                                                <i class="iconfont">&#xe6ba;</i>
                                            </span>
                                            <span class="gender-box male" v-else>
                                                <i class="iconfont">&#xe608;</i>
                                            </span>
                                            {{userInfo.gender}}
                                </span>
                            </div>
                            <div class="left-item">
                                <span class="item-name">考试日期：</span>
                                <span class="item-content">{{userInfo.begin_time}}</span>
                            </div>
                            <div class="left-item">
                                <span class="item-name">考试名称：</span>
                                <span class="item-content">{{userInfo.exam_name}}</span>
                            </div>
                            <div class="left-item">
                                <span class="item-name">试卷得分：</span>
                                <span class="item-content score">{{userInfo.total_score}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="head-right">
                <div class="head-wrap">
                    <div class="report-title">得分情况</div>
                    <div class="item-content">
                        <div id="scoreCondition" style="width: 100%;height:100%;position: relative"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-box">
            <div class="middle-wrap">
                <div class="report-title">实操题报告</div>
            </div>
            <div class="item-content">
                <div id="practicalCondition" style="width: 100%;height:100%;position: relative"></div>
            </div>
        </div>
        <div class="bottom-box">
            <div class="bottom-wrap">
                <div class="report-title">理论题报告</div>
                <div class="tips-box" v-if="theoryList.length > 0">
                    本次试卷共{{essayInfo.total_num}}题，本次作答您答对了{{essayInfo.total_true}}道题
                </div>
            </div>
            <div class="list-box" v-if="theoryList.length > 0">
                <div class="item-content" v-for="(item,index) in theoryList" :key=index
                     :style="{background:index % 2 == 0 ?'#F6FFFB':'#fff'}">
                    <div class="item-head">
                        <div class="head-title">
                            <div class="first-title">{{item.tag_name}}({{item.total_num}}个知识点)</div>
                        </div>
                        <div class="head-title">共答对{{item.total_true}}个</div>
                        <div class="head-title">共答错{{item.total_false}}个</div>
                        <div class="head-title">
                            <el-progress
                                    :percentage="Number(((Number(item.total_true) / Number(item.total_num)) * 100 ).toFixed(0))"
                                    :color="customColorMethod(((Number(item.total_true) / Number(item.total_num)) * 100 ).toFixed(0))"></el-progress>
                        </div>
                    </div>
                    <div class="item-item" v-for="(sItem,sIndex) in item.children" :key="sIndex">
                        <div class="item-title">{{sItem.tag_name}}:{{sItem.total_num}}个知识点</div>
                        <div class="item-title">答对{{sItem.total_true}}个</div>
                        <div class="item-title">答错{{sItem.total_false}}个</div>
                        <div class="item-title">
                            <el-progress
                                    :percentage="Number(((Number(sItem.total_true) / Number(sItem.total_num)) *100).toFixed(0))"
                                    :color="customColorMethod(((Number(sItem.total_true) / Number(sItem.total_num)) *100).toFixed(0))"></el-progress>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-box" v-else>
                <div class="item-content">
                    <div class="no-data">暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import echarts from "echarts";

    export default {
        name: "SingleReport",
        props: ['studentInfo'],
        data() {
            return {
                userInfo: {},
                essayInfo: {},
                operationInfo: [],
                theoryList: [],
            }
        },
        mounted() {
            this.formatData();
            this.getScoreCondition();
            this.getPracticeCondition();
        },
        methods: {
            formatData() {
                this.userInfo = this.studentInfo.user_info;
                this.essayInfo = this.studentInfo.essay_info;
                this.operationInfo = this.studentInfo.operation_info;
                if (this.essayInfo.list) {
                    this.theoryList = this.essayInfo.list
                }
            },
            getScoreCondition() {
                this.$nextTick(() => {
                    let scoreCondition = this.$echarts.init(document.getElementById('scoreCondition'));
                    let userInfo = this.studentInfo.user_info;
                    scoreCondition.setOption({
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            padding: [0, 100, 0, 0],
                            orient: 'horizontal',  //布局  纵向布局 图例标记居文字的左边 vertical则反之
                            width: 300,      //图行例组件的宽度,默认自适应
                            x: 'center',   //图例显示在右边
                            y: 'bottom',   //图例在垂直方向上面显示居中
                            itemWidth: 10,  //图例标记的图形宽度
                            itemHeight: 10, //图例标记的图形高度
                            textStyle: {
                                color: '#000',
                                fontSize: 14,
                                lineHeight: 22
                            },
                        },
                        series: [
                            {
                                type: 'pie',
                                center: ['50%', '50%'],
                                radius: ['55%', '70%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: true,
                                    position: 'center',
                                    formatter: function (argument) {
                                        let html;
                                        html = '理论题:' + userInfo.essay_score + '\n\n' + '实操题:' + userInfo.operation_score;
                                        return html;
                                    },
                                    textStyle: {
                                        fontSize: 15,
                                        color: '#999999'

                                    },
                                },
                                emphasis: {
                                    label: {
                                        show: false,
                                        fontSize: '40',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: true
                                },
                                data: [
                                    {
                                        value: userInfo.operation_score,
                                        name: '实操分',
                                        itemStyle: {
                                            normal: {
                                                color: { // 完成的圆环的颜色
                                                    colorStops: [{
                                                        offset: 0,
                                                        color: '#FDAE95' // 0% 处的颜色
                                                    }, {
                                                        offset: 1,
                                                        color: '#F9727E' // 100% 处的颜色
                                                    }]
                                                }
                                            }
                                        },
                                    },
                                    {
                                        name: '理论分',
                                        value: userInfo.essay_score,
                                        itemStyle: {
                                            normal: {
                                                color: { // 完成的圆环的颜色
                                                    colorStops: [{
                                                        offset: 0,
                                                        color: '#BEE7D0' // 0% 处的颜色
                                                    }, {
                                                        offset: 1,
                                                        color: '#2DC079' // 100% 处的颜色
                                                    }]
                                                }
                                            }
                                        },
                                    },
                                    {
                                        name: '',
                                        value: 20,
                                        itemStyle: {
                                            normal: {
                                                color: '#F5F7F6'
                                            }
                                        },
                                    },
                                ]
                            }
                        ]
                    })
                })
            },
            getPracticeCondition() {
                let arr = [];
                for (let i in this.operationInfo) {
                    let obj = {
                        score: this.operationInfo[i].score,
                        score_rate: this.operationInfo[i].score_rate,
                        name: this.formatHeadLineType(i)
                    }
                    arr.push(obj)
                }
                let dataX = [];
                let dataY = [];
                let lineY = [];
                arr.forEach(item => {
                    dataX.push(item.name);
                    dataY.push(item.score);
                    lineY.push(item.score_rate);
                })
                this.$nextTick(() => {
                    let practicalCondition = this.$echarts.init(document.getElementById('practicalCondition'));
                    practicalCondition.setOption({
                        title: {
                            x: 'center',
                            y: 'bottom',
                            textStyle: { //设置主标题风格
                                Color: '#333333',//设置主标题字体颜色
                                fontSize: 14,
                                fontStyle: 'normal',
                            },
                        },
                        tooltip: {
                            trigger: 'axis',
                            padding: 0,
                            axisPointer: {
                                type: 'line',
                                lineStyle: {
                                    type: 'solid',
                                    width: 1,
                                    color: '#e0e2eb'
                                }
                            },
                        },
                        legend: {
                            show: true,
                            selectedMode: 'multiple',    //设置显示单一图例的图形，点击可切换
                            bottom: 0,
                            left: 500,
                            textStyle: {
                                color: '#666',
                                fontSize: 12
                            },
                            itemGap: 20,
                            data: ['分数', '比例'],
                        },
                        grid: { //图表和父盒子之间的距离
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            top: '10%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: dataX,
                                boundaryGap: true,
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisLabel: {
                                    interval: 0,
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '分数',
                                min: 0,
                                max: 100,
                                interval: 20,
                                axisLine: {
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}'
                                }
                            },
                            {
                                type: 'value',
                                name: '比例',
                                min: 0,
                                max: 100,
                                interval: 20,
                                axisLine: {
                                    lineStyle: {
                                        color: '#999999'
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}%'
                                }
                            },
                        ],
                        series: [
                            {
                                name: '分数',
                                type: 'bar',
                                barWidth: '18px',
                                data: dataY,
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            {offset: 0, color: '#60C5FF'},
                                            {offset: 1, color: '#428AF5'}
                                        ]
                                    ),
                                    barBorderRadius: [5, 5, 0, 0],
                                },
                                label: {
                                    normal: {
                                        show: false,
                                        position: 'top'
                                    }
                                },
                            },
                            {
                                name: '比例',
                                type: 'line',
                                yAxisIndex: 1,
                                symbol: "none",
                                itemStyle: {
                                    color: '#2DC079',
                                },
                                data: lineY
                            }
                        ]
                    })
                })
            },
            customColorMethod(percentage) {
                if (Number(percentage) < 59) {
                    return '#F23B2F';
                } else if (Number(percentage) < 79) {
                    return '#2C95FF';
                } else {
                    return '#2DC079';
                }
            },
            formatHeadLineType(type) {
                const types = {
                    'info_collect': '商品信息采集与处理',
                    'shop_management': '网店管理',
                    'shop_decoration': '网店装修',
                    'network_promotion': '网络推广',
                    'data_capture_clean': '电子商务数据收集与清洗',
                    'store_customer_service': '网店客户服务',
                }
                return types[type]
            },
        }
    }
</script>

<style scoped lang="scss">
    .info-wrap {

        .report-title {
            font-size: 18px;
            position: relative;

            &:before {
                content: '';
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                position: absolute;
                left: -13px;
                top: 3px;
            }
        }

        .head-box {
            display: flex;

            .head-left {
                background: #fff;
                border-radius: 8px;

                .head-wrap {
                    padding: 35px 125px 35px 30px;
                }

                .left-main {
                    display: flex;
                    margin-top: 31px;

                    .left-main-content {
                        margin-left: 31px;

                        .stu-name {
                            font-size: 30px;
                            font-weight: 500;
                        }

                        .left-item {
                            margin-top: 23px;
                            display: flex;
                            align-items: center;

                            .item-name {
                                width: 90px;
                                font-size: 18px;
                                color: #666666;

                                &.gender {
                                    width: 72px;
                                    display: flex;
                                    justify-content: space-between;
                                }
                            }

                            .item-content {
                                font-size: 18px;
                                color: #333333;

                                .gender-box {
                                    i {
                                        font-size: 18px;
                                    }

                                    &.female {
                                        i {
                                            color: #FF3EC9;
                                        }
                                    }

                                    &.male {
                                        i {
                                            color: #75B9EB;
                                        }
                                    }
                                }

                                &.score {
                                    font-size: 30px;
                                    color: #F23B2F;
                                }
                            }
                        }
                    }
                }

                img {
                    width: 200px;
                    height: 280px;
                }
            }

            .head-right {
                background: #fff;
                margin-left: 10px;
                border-radius: 8px;

                .head-wrap {
                    padding: 35px 65px 35px 30px;
                }

                .item-content {
                    margin-top: 24px;
                    width: 300px;
                    height: 250px;

                    #scoreCondition {
                        background: url('../assets/images/teacher/star-background.png')
                    }
                }
            }
        }

        .info-item {
            margin-top: 20px;
            background: #FFFFFF;
            border-radius: 8px;

            .item-wrap {
                padding: 33px 30px 0;
            }

            .item-content {
                padding: 33px 100px 20px;
                display: flex;
                justify-content: space-between;

                .item-progress {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .progress-content {
                        text-align: center;
                    }

                    ::v-deep.el-progress {

                        .el-progress-circle {
                            width: 170px !important;
                            height: 170px !important;
                        }

                        .el-progress__text {
                            width: 92px;
                            height: 92px;
                            background: #F9F9F9;
                            border-radius: 50%;
                            left: 24%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 24px;
                            font-weight: bold;
                            color: #313131;
                        }
                    }

                    .progress-title {
                        text-align: center;
                        margin-top: 20px;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
            }
        }

        .middle-box {
            margin-top: 20px;
            background: #FFFFFF;
            border-radius: 8px;

            .middle-wrap {
                padding: 33px 30px 0;
            }

            .item-content {
                margin-top: 46px;
                padding-bottom: 23px;
                height: 350px;
            }
        }

        .bottom-box {
            margin-top: 17px;
            padding-bottom: 30px;

            .bottom-wrap {
                background: #FFFFFF;
                border-radius: 8px;
                padding: 33px 30px 0;

                .tips-box {
                    margin-top: 24px;
                    font-size: 16px;
                    color: #666666;
                }
            }

            .list-box {
                padding-top: 42px;
                padding-bottom: 38px;
                background: #ffffff;

                .item-content {

                    .item-head {
                        padding: 10px 30px;
                        min-height: 30px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #eeeeee;

                        .head-title {
                            flex: 1;
                            font-size: 16px;
                            color: #333333;

                            .first-title {
                                width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }

                    .no-data {
                        text-align: center;
                        font-size: 16px;
                        color: #333333;
                    }
                }

                .item-item {
                    display: flex;
                    padding: 0 30px 19px;

                    &:nth-of-type(2) {
                        padding-top: 19px;
                    }

                    .item-title {
                        flex: 1;
                        font-size: 14px;
                        color: #666666;
                    }
                }
            }
        }

    }
</style>