import { render, staticRenderFns } from "./SingleReport.vue?vue&type=template&id=4dbf6efe&scoped=true"
import script from "./SingleReport.vue?vue&type=script&lang=js"
export * from "./SingleReport.vue?vue&type=script&lang=js"
import style0 from "./SingleReport.vue?vue&type=style&index=0&id=4dbf6efe&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dbf6efe",
  null
  
)

export default component.exports